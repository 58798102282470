import React from "react";
import {graphql} from "gatsby";

import Layout from "../../../components/layout";
import HelpMethodsSection from "../../../components/helpMethodsSection";
import RecentNewsPosts from "../../../components/recentNewsPosts";
import {NewsArticleJsonLd} from 'gatsby-plugin-next-seo';
import ImageGallery from "react-image-gallery";

function NewsPost(props) {
  const {data, location} = props;
  const {title, date, dateISO, coverPicture, keywords, newsGallery} = data.markdownRemark.frontmatter;
  const __html = data.markdownRemark.html;
  const siteUrl = data.site.siteMetadata.url;

  const galleryImages = (newsGallery || []).map(({image, caption}) => ({
    original: image,
    thumbnail: image,
    description: caption,
  }));

  return (
    <Layout activeLink="/stay-informed" pageTitle={title} {...props}>
      <NewsArticleJsonLd
        url={`${siteUrl}${location.pathname}`}
        title={title}
        images={[coverPicture]}
        keywords={keywords || ""}
        datePublished={dateISO}
        dateModified={dateISO}
      />

      <div className="md:flex flex-row mt-10 xl:mt-20">
        <div className="md:w-2/3 px-10 md:pl-20 mb-10">
          <p className="text-4xl mb-5">{title}</p>
          <p className="text-xl mb-10">{date}</p>
          <div className="text-justify markdown-html mb-10" dangerouslySetInnerHTML={{__html}}/>
          {!!newsGallery?.length && (
            <div>
              <ImageGallery items={galleryImages} showThumbnails={false}/>
            </div>
          )}
        </div>

        <div className="md:w-1/3 mb-20">
          <p className="text-2xl font-bold text-center text-gray-700 mb-5">RECENT POSTS</p>
          <RecentNewsPosts/>
        </div>
      </div>
      <HelpMethodsSection/>
    </Layout>
  )
}

export default NewsPost;

export const newsPostQuery = graphql`
  query NewsPostQuery($slug: String) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        dateISO: date
        title
        coverPicture
        newsGallery {
          caption
          image
        }
      }
      html
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`;
