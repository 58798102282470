import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import placeholderImage from '../img/components/navbar/raphael-logo.png';

function RecentNewsPosts() {
  const data = useStaticQuery(graphql`
    query RecentNewsPostsQuery {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/pages/news/"}}
        limit: 10
        sort: {fields: frontmatter___date, order: DESC}
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "MMMM D, YYYY")
              coverPicture
            }
          }
        }
      }
    }
  `);
  const newsPosts = data.allMarkdownRemark.edges.map(newsPost => ({
    ...newsPost.node.fields,
    ...newsPost.node.frontmatter,
  }));

  return (
    <div className="flex flex-row flex-wrap justify-evenly">
      {newsPosts.map(({title, date, coverPicture, slug}, index) => (
        <div key={index}
          className="w-full max-w-sm m-5 rounded overflow-hidden transition-all shadow-md hover:shadow-xl hover:bg-gray-200">
          <a href={slug} className="flex flex-col sm:flex-row">
            <img className={`flex-1 max-h-32 overflow-hidden ${coverPicture ? "object-cover" : "object-contain"}`} src={coverPicture || placeholderImage} alt={title}/>
            <div className="flex-1 px-4 py-2">
              <div className="font-bold text-md mb-2">{title}</div>
              <div className="text-sm mb-2">{date}</div>
            </div>
          </a>
        </div>
      ))}
      <div className="w-full max-w-sm rounded overflow-hidden transition-all shadow-md hover:shadow-xl bg-gray-200 hover:bg-gray-300">
          <a href="/news/" className="flex flex-col items-center justify-center h-full">
              <div className="font-bold text-md mb-2 text-gray-700 py-2">View All Posts</div>
          </a>
        </div>
    </div>
  );
}

export default RecentNewsPosts;
